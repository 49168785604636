<template>	
    <div>		
		<el-card class="box-card MT20" v-loading="dataLoading">
			<div slot="header" class="clearfix">
			    <span>
			        <span class="el-icon-data-line"></span> 分时销售数据 <span class="color-danger">（注意：分时统计展示的是历史销售数据，即当时售出的门票数（包括后来可能取消的门票），而非当前实际销售票数）</span>
			    </span>
			</div>
			<div class="page-filter-box" v-if="ticketNameData">
			    <el-row>			        
					<el-col :span="4">
						<el-select v-model="selectKey" placeholder="请选择" size="medium" @change="changeActivity" clearable>
						    <el-option v-for="(item, index) in ticketNameData" :key="index" :label="item.value" :value="item.key"></el-option>
						</el-select>
					</el-col>
			         <el-col :span="3">
			            <el-radio-group v-model="timeType" class="MR20" size="medium" @change="getTime">
			                <el-radio-button :label="0">小时</el-radio-button>
			            	<el-radio-button :label="1">天</el-radio-button>
			            </el-radio-group>
			        </el-col>					
			        <el-col :span="8" >
						   <el-date-picker v-model="daterange" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false" unlink-panels :picker-options="pickerOptions" @change="daterangeChange"></el-date-picker>
			        </el-col>
					<el-col :span="5">
						<div class="color-danger ML20" >按小时统计只能看最多7天的数据<br>按天统计只能看最多一年的数据</div>
					</el-col>					
			    </el-row>
			</div>
			<div class="content">
			   <div id="chartContainer" ref="chartContainer"></div>
			</div>
		</el-card>       
    </div>
</template>
<script>
import { Line } from '@antv/g2plot';

export default {
    props: {
        id: {
            type: [Number, String],
            default: () => null
        },
    },
    components: {},
    data() {
        return {
            dataLoading: false,	
			daterange: [],
			ticketNameData:{},		
			selectKey:'',		
			chartData:[],		
			line:null,		
				
			
			timeType:1,
			startTime:'',
			endTime:'',
			
			timeData:null,
			chart:null,
			
			
			
			pickerMinDate1: "",//第一次选中的时间
			pickerOptions: {
			    onPick: obj => {
			        this.pickerMinDate1 = new Date(obj.minDate).getTime();
			    },
			    disabledDate: (time) => {
			        if (this.pickerMinDate1 && time.getTime() <= Date.now()) {
			            const day1 = 730 * 24 * 3600 * 1000;
			            let maxTime = this.pickerMinDate1 + day1;
			            let minTime = this.pickerMinDate1 - day1;
			            return time.getTime() > maxTime || time.getTime() < minTime;
			        }else{
			            return time.getTime() > Date.now();
			        }
			    }
			},
        }
    }, 
	mounted() {
		
		this.getSessionName()
	},
    methods: {
		getSessionName(){
			this.$request({
			    url: "/activitySaleStatistice/staSelect",
			    params: { activityId: parseInt(this.id) },
			    method: "post",
			})
			    .then((res) => {
			        const { state, msg, result } = res.data;
			        if (state == 1 && result) {
						this.ticketNameData = result
						this.selectKey = this.ticketNameData[0].key
						this.getTime(1)
			        } else {
			            this.$message.error(msg || "获取信息失败,请重试");
			        }
			    })
			    .catch(() => {
			        this.$message.error("获取信息失败,请重试");
			    })
			    .finally(() => {
			        this.dataLoading = false;
			    });
		},
		getTime(val){
			this.$request({
				url: "/activitySaleStatistice/time",
				data: {},
				method: "post",
			})
				.then((res) => {
					const { state, msg, result } = res.data;
					if (state == 1) {
						if( val == 1 ){
							this.daterange = [result[1].startTime,result[1].endTime]							
						}else{
							this.daterange = [result[0].startTime,result[0].endTime]
						}
						this.getTimedata()
					} else {
						this.$message.error(msg || "获取数据失败,请重试");
					}
				})
				.catch(() => {
					this.$message.error("获取数据失败,请重试");
				})
				.finally(() => {
					this.dataLoading = false;
				});  
		},	  
	   getTimedata(){
		   this.dataLoading = true
		   this.$request({
		       url: "/activitySaleStatistice/timeStats",
		       data: { 
				   activityId: parseInt(this.id),
				   startTime:this.daterange && this.daterange[0] || '',
				   endTime:this.daterange && this.daterange[1] || '',
				   selectKey:this.selectKey,				   
				   timeType:this.timeType				   
				},
		       method: "post",
		   })
		       .then((res) => {
		           const { state, msg, result } = res.data;
		           if (state == 1) {
		               this.timeData = result;					   
					   var data = []
					   if( this.timeData ){
						   this.timeData.forEach((tab, idx) => {							   
							   tab.value.forEach((tabs, idxs) => {
								   if( tabs.value > 0 ){
									   data.push({
										   year:tabs.name,
										   value:tabs.value,
										   category:tab.name
									   })
								   }
								   
							   })
						   })
						  
						  this.chartData = data.sort()
						  this.chartData.sort((a, b) => a.year - b.year )
						 
							console.log(this.chartData)
						   this.$nextTick(() => {
						       this.drawChart();						    
						   })
					   }
					   this.dataLoading = false;
		           } else {
		               this.$message.error(msg || "获取数据失败,请重试");
		           }
		       })
		       // .catch(() => {
		       //     this.$message.error("获取数据失败,请重试");
		       // })
		       // .finally(() => {
		       //     this.dataLoading = false;
		       // });
	   },
	   changeActivity(value){
	   		this.selectKey = value
	   		this.getTimedata()
	   },	   
	   drawChart(){
		   if( this.line ) this.line.destroy();
		   var data = this.chartData
		   this.line = new Line('chartContainer', {
		         data,
		         xField: 'year',
		         yField: 'value',
		         seriesField: 'category',
		         xAxis: {
		          
		         },
		         yAxis: {
		           label: {
		             // 数值格式化为千分位
		             formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
		           },
		         },
		       });
		   
		       this.line.render();
	       
	   },
	   
	   //选择自定义时间段
	   daterangeChange(e){
	      this.getTimedata()
	   }
	   
    }
}
</script>